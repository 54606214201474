.Header {
    width: 228px;
    display: flex;
}

@media all and (max-width: 500px) {
    .Header {
        width: 60px;
    }
}

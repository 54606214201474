/* Bold */

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url('fonts/latoheavy.ttf') format('truetype'), url('fonts/latoheavy.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url('fonts/latomedium.ttf') format('truetype'), url('fonts/latomedium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

html {
    font-size: 18px;
}

body {
    margin: 0;
    font-family: 'Lato', Helvetica, Arial, sans-serif, -apple-system;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    color: #0d4cd3;
    cursor: pointer;
    text-decoration: none;
}

h1 span {
    box-shadow: inset 0 0 #fff, inset 0 -23px #fdc73e;
}

.apexcharts-tooltip {
    color: #000;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    border: none;
}

.DateRangePickerInput__withBorder {
    border-radius: 4px !important;
    margin: 8px;
    border-color: rgb(181, 181, 181) !important;
}

.DateInput_input,
.DateInput {
    border-radius: 4px !important;
}

.DateInput_input {
    padding: 9px 11px 6px !important;
    font-size: 16px !important;
}

.DateInput {
    width: 108px !important;
}

.__jivoMobileButton {
    z-index: 999 !important;
}

.MuiInputAdornment-positionEnd {
    margin: 0;
}

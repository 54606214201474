.Main {
    width: 100%;
    min-height: calc(100vh - 66px);
    flex-grow: 1;
    padding: 20px;
    margin-top: 66px;
}

.Container {
    background-color: #fff;
    min-height: 100%;
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
}

@media all and (max-width: 1250px) {
    .Main {
        background: #fff;
        padding: 0;
    }

    .Container {
        padding: 20px;
    }
}

@media all and (max-width: 700px) {
    .Container {
        padding: 15px;
    }
}
